
import { Watch, Mixins, Component, Ref } from "vue-property-decorator";

import TemplateTable from "./component/templateTable.vue";
import TemplateAdd from "./component/templateAdd.vue";

import { moxaOrderTemplateListApi, c_moxaOrderTemplateListApi, c_moxaPackageTemplateConApi, moxaOrderTemplateAcupointOptionApi, c_moxaOrderTemplateAcupointOptionApi, moxaOrderTemplateMoxaOptionApi, c_moxaOrderTemplateMoxaOptionApi, moxaPackageTemplateConApi } from "@/api/moxibustion";

@Component({
  components: { TemplateTable, TemplateAdd }
})
export default class Template extends Mixins() {
  @Ref("TemplateTable") TemplateTableList: TemplateTable;

  tabList = [
    {
      id: 0,
      label: "艾灸大师A01",
      listApi: moxaOrderTemplateListApi, // 全部模板套餐列表
      detailApi: moxaPackageTemplateConApi, // 模板穴位套餐详情
      acupointListApi: moxaOrderTemplateAcupointOptionApi,  // 穴位列表
      moxaListApi: moxaOrderTemplateMoxaOptionApi   // 灸法列表
    },
    {
      id: 1,
      label: "艾灸大师专业版/C01",
      listApi: c_moxaOrderTemplateListApi,
      detailApi: c_moxaPackageTemplateConApi,
      acupointListApi: c_moxaOrderTemplateAcupointOptionApi,
      moxaListApi: c_moxaOrderTemplateMoxaOptionApi
    },
  ]

  queryForm = {
    name: "",
    status: "",  // 默认传-1 0代表禁用，1代表启用
  };
  currentTab = "0";
  addTemplateFlag = false;
  statusOptions = [{ label: "已启用", value: 1 }, { label: "已禁用", value: 0 }];

  mounted() {
    this.TemplateTableList[this.currentTab]?.getData();
  }

  // 搜索
  handleSearch() {
    this.TemplateTableList[this.currentTab]?.resetPages();
    this.TemplateTableList[this.currentTab]?.getData();
  }

  refreshTable() {
    this.getTabTableData(this.currentTab);
  }

  add() {
    this.addTemplateFlag = true;
  }

  @Watch("currentTab")
  getTabTableData(value) {
    this.queryForm = {
      name: "",
      status: "",
    };
    this.$nextTick(() => {
      this.TemplateTableList[value]?.getData();
    })
  }
}
