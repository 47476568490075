
import { Mixins, Component, PropSync, Ref, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import { moxaOrderTemplateAddApi } from "@/api/moxibustion";

const initRow = { acupointId: "", moxibustionId: "", time: undefined }

@Component({
  components: {},
})
export default class TemplateAdd extends Mixins() {
  @PropSync("formVisible") addTemplateFlag: boolean;
  @Prop({ default: "" }) acupointListApi: any;
  @Prop({ default: "" }) moxaListApi: any;
  @Prop({ default: "" }) deviceType: string;
  @Prop({ default: "" }) typeId: string;
  @Ref("formRef") formRef: VForm;

  formData = {
    tempName: "",
    rules: {
      tempName: { required: true, message: "请输入模板名称", trigger: ['blur', 'change'] },
      acupointId: { required: true, message: '请选择穴位', trigger: ['blur', 'change'] },
      moxibustionId: { required: true, message: '请选择灸法', trigger: ['blur', 'change'] },
      time: { required: true, message: '请输入时长', trigger: ['blur'] },
    },
    tableData: [{ acupointId: "", moxibustionId: "", time: undefined }],
  }

  xwOptions = [];
  jfOptions = [];

  saveLoading = false;

  get totalCountTimes() {
    const count = this.formData.tableData?.reduce((acc, cur) => {
      acc = acc + (cur.time ? Number(cur.time) : 0);
      return acc;
    }, 0)
    return count;
  }

  getOptions() {
    this.acupointListApi({}).then((res) => {
      this.xwOptions = res.data || [];
      this.xwOptions?.forEach((op) => {
        op.value = op.code;
        op.label = op.name;
      })
    })
  }

  cancel() {
    this.formRef?.resetFields();
    this.addTemplateFlag = false;
    this.formData.tableData = [cloneDeep(initRow)]
  }

  addRow() {
    this.formData.tableData.push(cloneDeep(initRow));
    this.$nextTick(() => {
      const formTableEL = document.querySelector(".formTable");
      const tableWrapper = formTableEL?.querySelector(".el-table__body-wrapper");
      if (tableWrapper) {
        tableWrapper.scrollTop = tableWrapper.scrollHeight;
      }
    })
  }

  delRow(row, index) {
    if (this.formData.tableData?.length <= 1) return;
    this.formData.tableData.splice(index, 1);
  }

  acupointIdChange(row) {
    row.moxibustionId = "";
  }

  moxibustionIdChange(row) {
    this.getMoxaOption(row.acupointId);
  }

  getMoxaOption(acupoint_id) {
    this.moxaListApi(acupoint_id).then((res) => {
      let options = res.data || [];
      options?.forEach((op) => {
        op.value = op.code;
        op.label = op.name;
      })
      this.jfOptions = cloneDeep(options);
    }).catch(() => {
      this.jfOptions = [];
    })
  }

  save() {
    this.formRef.validate().then(() => {
      const params = {
        tempName: this.formData.tempName,
        moxaVersion: this.typeId,
        dtoList: this.formData.tableData || [],
      }
      this.saveLoading = true;
      moxaOrderTemplateAddApi(params).then((res) => {
        this.$emit("refreshTable");
        this.cancel();
      }).finally(() => {
        this.saveLoading = false;
      })
    })
  }

  @Watch("addTemplateFlag")
  watchAddTemplateFlag(val) {
    if(val) {
      this.getOptions();
    }
  }
}
