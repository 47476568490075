
import { Mixins, Component, Prop } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { MoxibustionStatusEnum } from "@/enum/moxibustion.enum";

import TemplateDetail from "./templateDetail.vue";

import { moxaOrderTemplateListApi, moxaOrderTemplatePublishApi, moxaOrderTemplateAddApi, moxaOrderTemplateAcupointOptionApi, moxaOrderTemplateMoxaOptionApi, moxaPackageTemplateConApi, moxaPackageEnableTemplateApi } from "@/api/moxibustion";

@Component({
  components: { TemplateDetail }
})
export default class TemplateTable extends Mixins(loading, tablePagination, Resize) {
  @Prop({ default: "" }) listApi: any;
  @Prop({ default: "" }) detailApi: any;
  @Prop({ default: {} }) queryForm: any;
  @Prop({ default: 0 }) typeId: number;

  tableData = [];
  itemTotal = 0;

  showTemplateDetail = false;
  dialogTitle = "";
  packageData = "";
  publishAllLoading = false;

  moxibustionStatusEnum = MoxibustionStatusEnum;

  mounted() {
    this.windowResize(270);
  }

  getData() {
    this.showLoading();
    const queryForm = {
      ...this.queryForm,
      status: [0, 1, "0", "1"].includes(this.queryForm.status) ? this.queryForm.status : -1,
    }
    if (!this.listApi) return;
    this.listApi({
      ...queryForm,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    }).then(res => {
      this.hideLoading();
      this.tableData = res.data.list || [];
      this.tableData.forEach((el) => {
        el.totalTimes = el.moxaPackageContentList.reduce((calc, curr) => {
          return calc + curr.time;
        }, 0)
      })
      this.itemTotal = res.data.total;
    }).finally(() => {
      this.hideLoading();
    });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  viewTemplateDetail(row) {
    this.showTemplateDetail = true;
    this.dialogTitle = row.moxaTempName;
    this.packageData = row.id;
  }

  publishRow(row) {
    const { moxaPackageContentList, moxaTempName } = row;
    const content = moxaPackageContentList?.map((el) => ({ acupoint: el.acupointId, model: el.moxibustionId, time: el.time }))
    moxaOrderTemplatePublishApi({ moxaVersion: this.typeId, name: moxaTempName, content }).then(() => {
      this.getData();
      this.$message.success("发布成功");
    }).finally(() => this.publishAllLoading = false);
  }

  handleUpdateStatus(row) {
    const { operationStatus, moxaTempName } = row;
    this.$confirm(`是否${operationStatus == MoxibustionStatusEnum?.Enable ? "启用" : "禁用"}云端模板[${moxaTempName}]`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.doUpdateStatus(row.id);
    })
  }

  doUpdateStatus(id) {
    const params = {
      id,
      moxaVersion: this.typeId
    }
    moxaPackageEnableTemplateApi(params).then(() => {
      this.getData();
      this.$message.success("操作成功")
    })
  }
}
